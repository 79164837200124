<template>
    <div class="header-checkout-container" :style="'background-color:' + $config.dados.corprimaria + '; border-color: ' + $config.dados.corsecundaria">
        <div class="header-checkout-container1">
            <div class="header-checkout-container2">
                <img alt="logo" :src="$config.dados.logowhite" class="header-checkout-image" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'HeaderCheckout',
  props: {

  },
}
</script>

<style scoped>
.header-checkout-container {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  border-color: var(--dl-color-secundaria);
  border-width: 1px;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-principal);
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 5px;
}
.header-checkout-container1 {
  flex: 0 0 auto;
  width: 991px;
  height: 60px;
  display: flex;
  padding: 10px;
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.header-checkout-container2 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.header-checkout-image {
  height: 57px;
  object-fit: cover;
  margin-left: 0px;
}

@media(max-width: 991px) {

  .header-checkout-container1 {
    width: 100%;
    height: 41px;
  }
  .header-checkout-container2 {
    width: 100%;
    justify-content: flex-start;
  }
  .header-checkout-image {
    height: 37px;
  }
}
@media(max-width: 767px) {
  .header-checkout-container2 {
    justify-content: flex-start;
  }
}
</style>
